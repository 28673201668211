'use strict';

(function ($) {
    $.fn.rewAccordion = function () {
        var make = function make() {
            var accordionLinks = $(this).find('.accordion_heading');
            var accordionContent = $(this).find('.accordion__panel');

            for (var i = 0; i < accordionLinks.length; i++) {
                $(accordionLinks[i]).click(function (e) {
                    e.preventDefault();

                    var j = $(accordionLinks).index(this);

                    if ($(this).hasClass('active')) {

                        $(this).removeClass('active');
                        $(accordionContent[j]).slideUp();
                    } else {

                        $(accordionLinks).removeClass('active');
                        $(accordionContent).slideUp();

                        $(this).addClass('active');
                        $(accordionContent[j]).slideDown();
                    }
                });
            }
        };
        return this.each(make);
    };
})(jQuery);
(function ($) {
    $.fn.rewTabs = function () {
        var make = function make() {
            var tabLinks = $(this).find('.tabs li a');
            var tabContent = $(this).find('.tabs-content');

            for (var i = 0; i < tabLinks.length; i++) {
                $(tabLinks[i]).click(function (e) {
                    e.preventDefault();

                    $(tabLinks).removeClass('active');
                    $(tabContent).removeClass('active');

                    var j = $(tabLinks).index(this);

                    $(this).addClass('active');
                    $(tabContent[j]).addClass('active');
                });
            }
        };
        return this.each(make);
    };
})(jQuery);
(function ($) {
    "use strict";

    var DOMs = {

        documentBodyHtml: $("html, body"),

        menuTrigger: $("#menu-trigger"),

        sliderOne: $(".slider-one"),
        sliderCow: $(".slider-cow"),
        sliderAdvantage: $(".slider-advantage"),
        sliderAdvantageDouble: $(".slider-advantage-double"),
        sliderArrowLeft: "<div class='arrow left'><svg width=\"50px\" height=\"20px\" viewBox=\"0 0 50 20\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\"> <g id=\"Bootstrap-4-Grid-System\" stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\"> <g id=\"Skott-Smeat-landing-Copy-2\" transform=\"translate(-997.000000, -4798.000000)\" fill=\"#888888\" fill-rule=\"nonzero\"> <g id=\"Group-46\" transform=\"translate(387.000000, 4347.000000)\"> <g id=\"Group-40\" transform=\"translate(588.000000, 427.000000)\"> <g id=\"Group-2\"> <g id=\"Group-55\" transform=\"translate(22.000000, 24.000000)\"> <polygon id=\"Path-4\" points=\"9.04093565 19.0442737 8.27314924 19.6849796 0.34896749 10.1890795 8.27292494 0.679916035 9.04115994 1.32008397 1.65103251 10.1886235\"></polygon> <polygon id=\"Path-5\" points=\"1 10.5 1 9.5 49.4610081 9.5 49.4610081 10.5\"></polygon> </g> </g> </g> </g> </g> </g></svg></div>",
        sliderArrowRight: "<div class='arrow right'><svg width=\"50px\" height=\"19px\" viewBox=\"0 0 50 19\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\"> <g id=\"Bootstrap-4-Grid-System\" stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\"> <g id=\"Skott-Smeat-landing-Copy-2\" transform=\"translate(-1096.000000, -4798.000000)\" fill=\"#888888\" fill-rule=\"nonzero\"> <g id=\"Group-46\" transform=\"translate(387.000000, 4347.000000)\"> <g id=\"Group-40\" transform=\"translate(588.000000, 427.000000)\"> <g id=\"Group-2-Copy\" transform=\"translate(145.000000, 34.000000) scale(-1, 1) translate(-145.000000, -34.000000) translate(98.000000, 0.000000)\"> <path d=\"M22.6675686,33.070084 L70.4671124,33.070084 L70.4671124,34.070084 L22.7705251,34.070084 L29.6919682,42.3643577 L28.9241817,43.0050636 L21,33.5091634 L28.9239575,24 L29.6921925,24.6401679 L22.6675686,33.070084 Z\" id=\"Combined-Shape\"></path> </g> </g> </g> </g> </g></svg></div>",
        sliderArrowLeftSmall: "<div class='arrow small left'><svg width=\"30px\" height=\"19px\" viewBox=\"0 0 30 19\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\"> <g id=\"Bootstrap-4-Grid-System\" stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\"> <g id=\"Skott-Smeat-landing-Copy-2\" transform=\"translate(-1382.000000, -6768.000000)\" fill=\"#E31E2C\" fill-rule=\"nonzero\"> <g id=\"Group-9\" transform=\"translate(388.000000, 6653.000000)\"> <g id=\"Group-25\" transform=\"translate(520.000000, 90.000000)\"> <g id=\"Group-29\" transform=\"translate(455.000000, 0.000000)\"> <path d=\"M20.6998479,33.9976021 L49,33.9976021 L49,34.9973357 L20.6861304,34.9973357 L27.5604207,43.3594648 L26.8042543,44 L19,34.5066299 L26.8040334,25 L27.5606416,25.6399974 L20.6998479,33.9976021 Z\" id=\"Combined-Shape\"></path> </g> </g> </g> </g> </g></svg></div>",
        sliderArrowRightSmall: "<div class='arrow small right'><svg width=\"30px\" height=\"19px\" viewBox=\"0 0 30 19\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\"> <g id=\"Bootstrap-4-Grid-System\" stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\"> <g id=\"Skott-Smeat-landing-Copy-2\" transform=\"translate(-1382.000000, -6834.000000)\" fill=\"#888888\"> <g id=\"Group-9\" transform=\"translate(388.000000, 6653.000000)\"> <g id=\"Group-25\" transform=\"translate(520.000000, 90.000000)\"> <g id=\"Group-29\" transform=\"translate(455.000000, 0.000000)\"> <path d=\"M20.6998479,99.9976021 L49,99.9976021 L49,100.997336 L20.6861304,100.997336 L27.5604207,109.359465 L26.8042543,110 L19,100.50663 L26.8040334,91 L27.5606416,91.6399974 L20.6998479,99.9976021 Z\" id=\"Combined-Shape\" transform=\"translate(34.000000, 100.500000) scale(-1, 1) translate(-34.000000, -100.500000) \"></path> </g> </g> </g> </g> </g></svg></div>",
        sliderCounterOne: $(".slider-counter-one"),
        sliderCounterCow: $(".slider-counter-cow"),
        sliderCounterAdvantage: $(".slider-counter-advantage")
        //
        // linkSmooth: $(".smooth"),
        //

    };

    $(document).ready(function () {

        // ========== MENU ==========

        DOMs.menuTrigger.click(function (e) {
            e.preventDefault();
            DOMs.documentBodyHtml.toggleClass("open");
        });

        $('.menu-nav li a').click(function () {
            DOMs.documentBodyHtml.removeClass("open");
        });

        $('.menu-order .btn').click(function () {
            DOMs.documentBodyHtml.removeClass("open");
        });

        $('.menu-lang__link').click(function (e) {
            e.preventDefault();
        });

        // ========== SLIDER ==========

        // ----- SLICK SLIDER COUNTER -----
        DOMs.sliderOne.on("init reInit afterChange", function (event, slick, currentSlide) {
            var i = (currentSlide ? currentSlide : 0) + 1;
            DOMs.sliderCounterOne.text(i + ' / ' + slick.slideCount);
        });
        DOMs.sliderAdvantage.on("init reInit afterChange", function (event, slick, currentSlide) {
            var i = (currentSlide ? currentSlide : 0) + 1;
            DOMs.sliderCounterAdvantage.text(i + ' / ' + slick.slideCount);
        });
        DOMs.sliderCow.on("init reInit afterChange", function (event, slick, currentSlide) {
            var i = (currentSlide ? currentSlide : 0) + 1;
            DOMs.sliderCounterCow.text(i + ' / ' + slick.slideCount);
        });

        // ----- SLICK SLIDER -----
        DOMs.sliderOne.slick({
            prevArrow: DOMs.sliderArrowLeft,
            nextArrow: DOMs.sliderArrowRight,
            dots: false
        });

        DOMs.sliderCow.slick({
            prevArrow: DOMs.sliderArrowLeftSmall,
            nextArrow: DOMs.sliderArrowRightSmall,
            dots: false,
            fade: true,
            cssEase: 'linear',
            speed: 200
        }).on("afterChange", function () {
            activeCow();
        });

        DOMs.sliderAdvantage.slick({
            prevArrow: DOMs.sliderArrowLeft,
            nextArrow: DOMs.sliderArrowRight,
            dots: false,
            asNavFor: DOMs.sliderAdvantageDouble,
            infinite: true
        });

        DOMs.sliderAdvantageDouble.slick({
            arrows: false,
            dots: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            asNavFor: DOMs.sliderAdvantage,
            // focusOnSelect: true,
            infinite: true,
            responsive: [{
                breakpoint: 992,
                settings: {
                    slidesToShow: 1
                }
            }]
        });

        $('.slider-advantage-double .slick-slide').click(function (e) {
            e.preventDefault();
            var index = $(this).attr('data-slick-index');
            if (index !== '' && index !== undefined) {
                $('.slider-advantage').slick('slickGoTo', parseInt(index) + 1);
            }
        });

        $('.cow-svg__polygon[data-attr]').click(function (e) {
            e.preventDefault();
            $('.cow-svg__polygon').removeClass('active');
            $(this).addClass('active');
            var slideno = $(this).data('attr');
            $('.slider-cow').slick('slickGoTo', slideno - 1);
        });

        var activeCow = function activeCow() {
            var cowSlide = $('.slider-cow .slick-slide.slick-active').data('slick-index') + 1;
            $('.cow-svg__polygon').removeClass('active');
            $('.cow-svg__polygon[data-attr=' + cowSlide + ']').addClass('active');
        };

        activeCow();

        // ========== ACCORDION ==========
        // DOMs.accordion.rewAccordion();

        // ========== TABS ==========
        // DOMs.tabs.rewTabs();

        // ========== DATEPICKER ==========
        // DOMs.datepicker.flatpickr();

        // ========== SCROLLSPY ==========

        if ($(window).scrollTop() > 45) {
            $('nav').addClass("scroll");
        }

        // ----- SCROLLING CLASS CHANGE -----
        $(window).scroll(function () {
            if ($(this).scrollTop() > 45) {
                $('nav').addClass("scroll");
            } else {
                $('nav').removeClass("scroll");
            }
        });

        // ----- ANCHOR LINKS SCROLLING -----
        $('.smooth').click(function (e) {
            e.preventDefault();

            // document.querySelector(this.getAttribute('href')).scrollIntoView({
            //     behavior: 'smooth'
            // });

            // event.preventDefault();

            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top + 66
            }, 700);

            // $('html, body').animate({
            //     scrollTop: $($.attr(this, 'href')).offset().top - 70
            // }, 500);
        });

        // if (bowser.firefox) {
        //     DOMs.body.addClass('brow-firefox');
        // }
        // if (bowser.safari) {
        //     DOMs.body.addClass('brow-safari');
        // }
        // if (bowser.msie) {
        //     DOMs.body.addClass('brow-msie');
        // }
        // if (bowser.msedge) {
        //     DOMs.body.addClass('brow-msedge');
        // }

    });

    // ========== !!! RESPONSIVE SCRIPTS !!! ===========

    // $(window).on('load resize', function () {
    //     if (window.matchMedia("(max-width: 767px)").matches) {
    //
    //     } else if (window.matchMedia("(min-width: 768px)").matches) {
    //
    //     }
    // });
})(jQuery);

(function () {
    'use strict';

    function validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    var validButton = function validButton(form, button) {
        if (form.find('input[name="name"]').val() !== '' && form.find('input[name="email"]').val() !== '' && form.find('textarea[name="text"]').val() !== '') {
            if (validateEmail(form.find('input[name="email"]').val())) {
                button.addClass('ready');
            }
        } else {
            button.removeClass('ready');
        }
    };

    $('form input').on('input', function () {
        var form = $(this).parent().parent().parent(),
            button = form.find('button');

        validButton(form, button);
    });

    $('form textarea').on('input', function () {
        var form = $(this).parent().parent().parent(),
            button = form.find('button');

        validButton(form, button);
    });

    $('form').submit(function (e) {
        e.preventDefault();

        var form = $(this),
            send = true;

        if (form.find('input[name=name]').val() === '') {
            send = false;
            form.find('input[name=name]').addClass('error');
        }

        if (form.find('input[name=email]').val() === '' || !validateEmail(form.find('input[name="email"]').val())) {
            send = false;
            form.find('input[name=email]').addClass('error');
        }

        if (form.find('textarea[name=text]').val() === '') {
            send = false;
            form.find('textarea[name=text]').addClass('error');
        }

        if (send) {
            /**
             * SEND AJAX FORM
             */
            var method = form.attr('method'),
                url = form.attr('action'),
                data = form.serializeArray();

            $.ajax({
                method: method,
                url: url,
                data: data,
                success: function success(data) {
                    if (data) {
                        /**
                         * SUCCESS FORM SENDING
                         */
                        form.trigger('reset').addClass('success');
                    }
                }
            });
        }
    });

    $('form input').focus(function () {
        $(this).removeClass('error');
    });

    $('form textarea').focus(function () {
        $(this).removeClass('error');
    });
})();